<script setup lang="ts">

import type { Ref } from 'vue';
import { ref, computed } from 'vue';
import { useUserDataStore } from '@/user/store/userDataStore';
import type { BankAccount } from '@/api/bankAccount/types';
import {
  DsBadge,
  DsDropdown,
  DsIcon,
  DsModal,
  ModalVariant,
  NotificationVariant,
  useNotification
} from '@demvsystems/design-components';
import ShallowCardCollapsible from '@/application/components/ShallowCardCollapsible.vue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import EditBankAccountModal from '@/user/components/EditBankAccountModal.vue';
import DetailViewContainer from '@/contracts/components/DetailViewContainer.vue';
import { useContractStore } from '@/contracts/stores/contractStore';
import { useRouter } from 'vue-router';
import { Finanzmanager } from '@/application/router/types/types';
import { spartenMapper } from '@/application/utils/sparteFormatter';
import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events';
import { storeToRefs } from 'pinia';
import type { Contract } from "@/api/contracts/types";

interface Props {
  bankAccount: BankAccount;
}

const props = defineProps<Props>();

const router = useRouter();
const { show } = useNotification();
const { isMobile } = useDetectMobile();
const { contractsMap } = storeToRefs(useContractStore());
const { deleteBankAccountById } = useUserDataStore();

const showEditModal = ref<boolean>(false);
const confirmationModal: Ref<typeof DsModal | null> = ref(null);

const bankAccountContracts = computed(() => {
  return props.bankAccount.contractIds.reduce((acc, contractId) => {
    const contract = contractsMap.value.get(contractId);
    if (contract) {
      acc.push(contract);
    }
    return acc;
  }, [] as Contract[]);
})

const doAction = (item) => {
  Handler[item.action]()
}

const Handler = {
  edit: () => showEditModal.value = true,
  delete: () => handleDeleteAccount(),
};

async function submitDelete() {
  const res = await deleteBankAccountById(props.bankAccount.id)

  if (res) {
    trackEvent({ category: TrackEventCategory.BANK_ACCOUNT, action: 'Entfernen', name: 'click' });
    await router.push({ name: Finanzmanager.DATEN });
    show('Bankverbindung erfolgreich entfernt.', NotificationVariant.Success);
  } else {
    show('Es gab einen Fehler.', NotificationVariant.Error);
  }

}

const handleDeleteAccount = () => {
  confirmationModal.value?.open({
    confirmed: () => {
      submitDelete();
    },
  });
}

const items = [
  { text: 'Bearbeiten', action: 'edit', icon: 'pencil', class: 'text-gray-900' },
  { text: 'Löschen', action: 'delete', icon: 'trash', class: 'text-red-700' },
];

const bankAccountDetails = computed(() => [
  { label: 'IBAN', value: props.bankAccount.iban },
  { label: 'BIC', value: props.bankAccount.bic },
  { label: 'Kontoinhaber', value: props.bankAccount.depositor },
]);
</script>

<template>
  <DetailViewContainer>
    <template #title>
      <div class="flex flex-row items-center justify-between">
        <h3 class="text-gray-900">{{ bankAccount.bankName }}</h3>
        <DsDropdown
          :items="items"
          :width="260"
          placement="bottom-end"
          list-class="py-2"
          item-class="pt-0 pb-0"
          class="ml-auto"
        >
          <template #button="{ isOpen }">
            <DsIcon
              name="ellipsis"
              variant="regular"
              size="lg"
              class="text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-lg"
              :class="{'bg-gray-100': isOpen}"
            />
          </template>

          <template #item="{ item }">
            <div
              class="flex flex-row items-center gap-4 py-2 px-5"
              :class="[item.class]"
              @click.prevent="doAction(item)"
            >
              <DsIcon
                v-if="item.icon"
                :name="item.icon"
                variant="light"
              />
              {{ item.text }}
            </div>
          </template>
        </DsDropdown>
      </div>
    </template>

    <template #main>
      <div class="flex flex-col">
        <DsBadge
          v-if="bankAccount.main"
          type="primary"
          class="mr-auto"
        >
          Standard-Bankverbindung
        </DsBadge>
        <div class="flex flex-col items-start gap-3 mt-8">
          <div
            v-for="item in bankAccountDetails"
            :key="item.label"
            class="flex flex-col-reverse sm:flex-row justify-between md:items-end w-full"
          >
            <span class="font-normal text-gray-700/85 text-sm">{{ item.label }}</span>
            <div class="flex items-center gap-2">
              <span
                v-if="item.label === 'IBAN'"
                class="font-normal text-gray-700/85 text-sm"
              >endet auf</span>
              <span class="font-medium text-base text-gray-900">{{ item.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #extra>
      <ShallowCardCollapsible
        :title="`Verträge (${bankAccount.contractIds.length})`"
        :disabled="bankAccount.contractIds.length === 0"
        :open="bankAccount.contractIds.length > 0 && !isMobile"
        :max-height="'27rem'"
        no-hover
      >
        <RouterLink
          v-for="contract in bankAccountContracts"
          :key="contract.id"
          v-slot="{ navigate }"
          :to="{ name: Finanzmanager.VERTRAG, params: { id: contract.id} }"
          custom
        >
          <div
            class="flex flex-row items-center justify-between px-3 py-2.5 hover:bg-gray-100 rounded group"
            @click="navigate"
          >
            <div class="flex flex-col">
              <span class="font-medium text-gray-900">
                {{ spartenMapper(contract.product) }} - {{ contract.number }}
              </span>
              <span class="text-sm text-gray-700">{{ contract.company.name }}</span>
            </div>
            <DsIcon
              name="chevron-right"
              variant="light"
              size="sm"
              class="opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-gray-900"
            />
          </div>
        </RouterLink>
      </ShallowCardCollapsible>
    </template>
  </DetailViewContainer>
  <EditBankAccountModal
    :show="showEditModal"
    :bank-account="bankAccount"
    @close="() => showEditModal = false"
  />

  <DsModal
    ref="confirmationModal"
    title="Bankverbindung löschen"
    :variant="ModalVariant.Error"
    icon="trash"
    cancel-label="Abbrechen"
    confirm-label="OK"
  >
    Das Löschen der Bankverbindung kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie fortfahren möchten?
  </DsModal>
</template>
