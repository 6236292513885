import type { Router } from "vue-router";
import { Finanzmanager } from "@/application/router/types/types";
import type { NavigationFailure, RouteLocationRaw } from "vue-router";
import { captureException } from "@sentry/vue";

const Topics = {
  Contract: 'contract',
  Document: 'document',
  Checkup: 'checkup',
  Handlung: 'handlung',
} as const;


let routerRef: null | Router = null;

export function setRouter(router: Router) {
  routerRef = router;
}

export function getRouter() {
  return routerRef;
}

export async function routeTo(to: RouteLocationRaw): Promise<NavigationFailure|void|undefined> {
  if (!routerRef) return;

  return await routerRef.push(to);
}

export async function routeToLogout(reason: string): Promise<NavigationFailure|void|undefined> {
  if (!routerRef) return;
  if (routerRef.currentRoute.value.name === Finanzmanager.LOGOUT) return;

  return await routerRef.push({ name: Finanzmanager.LOGOUT, state: { reason } });
}

export async function routeToTopic(topic: string): Promise<NavigationFailure|void|undefined> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [type, identifier] = topic.split(':');

  switch(type) {
    case Topics.Handlung:
      return await routeToName(Finanzmanager.HANDLUNGEN);
    case Topics.Contract:
      return await routeToName(Finanzmanager.VERTRAEGE);
    case Topics.Document:
      return await routeToName(Finanzmanager.DOKUMENTE);
    case Topics.Checkup:
      return await routeToName(Finanzmanager.CHECKUP);
    default:
      captureException(new Error(`Route to unknown topic: ${topic}`));
      return;
  }
}


export async function routeToName(name: Finanzmanager): Promise<NavigationFailure|void|undefined> {
  return await routeTo({ name });
}
