<template>
  <div
    v-if="!finished"
    class="flex flex-col"
  >
    <div class="-mx-4 sm:-mx-6 overflow-y-auto">
      <div class="flex flex-col gap-4 px-4 sm:px-6 pt-5">
        <ContractForm
          :model-value="form"
          :validation-errors="errors"
          :update-field="updateField"
        />

        <div class="flex flex-row justify-center md:justify-end md:mr-12 mt-2">
          <DsButton
            :icon="addDocuments ? 'chevron-up' : 'chevron-down'"
            variant="clear"
            size="sm"
            @click="addDocuments = !addDocuments"
          >
            Dokument/-e hinzufügen
          </DsButton>
        </div>

        <div v-if="addDocuments" class="mb-5 space-y-4">
          <DsFormGroup
            label="Dokument/-e"
            :inline-reverse="!isMobile"
            class="whitespace-nowrap"
          >
            <div class="w-full flex flex-col items-end">
              <FileInput
                :accept="validFileTypes.join(', ')"
                class="mt-2.5 w-full md:w-80 whitespace-normal"
                @input="setFiles"
              >
                <DocTypesHint />
              </FileInput>

              <InvalidFilesHint
                :files="invalidFiles"
                :error-messages="errorMessages"
              />

              <DocumentList
                :files="validFiles"
                :file-names="fileNames"
                :disable-image-inputs="combine"
                class="mt-2.5 w-full md:w-80"
                @set-file-name="setFileName"
                @remove="remove"
              />

              <DsFormGroup
                v-if="hasMultipleImageFiles"
                label="Bilder zu einer PDF-Datei kombinieren"
                label-for="combine-images-check"
                class="ml-8 mt-1 mr-auto"
                inline
              >
                <DsCheckbox
                  id="combine-images-check"
                  v-model="combine"
                  :disabled="exceedsImageCombinationLimit"
                />
              </DsFormGroup>

              <ImageCombinationHint
                v-if="exceedsImageCombinationLimit"
                class="ml-1.5 mt-2.5"
              />
            </div>
          </DsFormGroup>

          <DsFormGroup
            label="Typ"
            :inline-reverse="!isMobile"
          >
            <div class="w-full flex justify-end">
              <DsSelect
                v-model="documentType"
                :data="documentTypeOptions"
                placeholder=""
                size="lg"
                class="w-full md:w-80"
              />
            </div>
          </DsFormGroup>
        </div>
      </div>

      <div
        v-if="documentsLoading"
        class="text-muted"
      >
        <span class="spinner-border spinner-border-sm mr-1.5" />
        {{ validFiles.length > 1 ? 'Dokumente werden' : 'Dokument wird' }} hochgeladen...
      </div>

      <DsAlert
        v-if="errorMsg"
        :label="errorMsg"
        type="error"
        class="my-5"
      />
    </div>

    <div class="flex flex-row justify-center sm:justify-end gap-3 mt-3">
      <DsButton
        variant="secondary"
        class="w-full sm:w-auto"
        size="lg"
        @click="$emit('close')"
      >
        Abbrechen
      </DsButton>
      <DsButton
        variant="primary"
        class="w-full sm:w-auto"
        size="lg"
        :handler="submit"
      >
        Vertrag anlegen
      </DsButton>
    </div>
  </div>

  <div
    v-else
    class="flex flex-col items-center gap-3 pt-5"
  >
    <DsAlert type="success">Das Anlegen des Vertrags war erfolgreich.</DsAlert>
    <DsAlert
      v-if="hasUploadError"
      type="success"
    >
      Bei dem Hochladen der Dokumente gab es jedoch einen Fehler.
    </DsAlert>
    <span class="text-sm text-center my-2.5">
      Damit zukünftig die Informationen zu
      diesem Vertrag automatisch übermittelt werden, wird sich Ihr Makler bald nochmal
      bei Ihnen melden.
    </span>

    <div class="flex flex-col-reverse md:flex-row justify-end w-full gap-3">
      <DsButton
        variant="secondary"
        size="lg"
        @click="$emit('close')"
      >
        Schließen
      </DsButton>
      <DsButton
        variant="primary"
        size="lg"
        @click="finished = false"
      >
        Weiteren Vertrag anlegen
      </DsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DsAlert,
  DsButton,
  DsCheckbox,
  DsFormGroup,
  DsSelect,
} from '@demvsystems/design-components';
import { captureException } from '@sentry/vue';
import { ref, watch } from 'vue';

import { createContract } from '@/api/contracts/contractsApi';
import { toOptions } from '@/application/composables/vselect';
import type { ContractData } from '@/contracts/types/types';
import DocTypesHint from '@/upload/components/DocTypesHint.vue';
import FileInput from '@/upload/components/FileInput.vue';
import DocumentList from '@/upload/components/FileList.vue';
import ImageCombinationHint from '@/upload/components/ImageCombinationHint.vue';
import InvalidFilesHint from '@/upload/components/InvalidFilesHint.vue';
import { useContractStore } from '@/contracts/stores/contractStore';
import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events';
import { checkForDigits, checkForLetters, toUpperCase } from '@/application/utils/strings';
import { useDetectMobile } from "@/application/composables/detectMobile";
import { useDocumentUpload } from "@/documents/composables/documentUpload";
import { useDocumentTypes } from "@/documents/composables/documentTypes";
import { useForm } from '@/api/lib/integration';
import ContractForm from '@/contracts/components/ContractForm.vue';

defineEmits(['close']);

const hasUploadError = ref(false);
const finished = ref(false);
const addDocuments = ref(false);
const combine = ref(false);
const documentType = ref<number | null>(null);
const { fetchContractsAndDepots } = useContractStore();

const { form, submit, updateField, errors, errorMsg, reset  } = useForm<ContractData, { contract_id: number }>(
  createContract,
  {
    sparte_id: null,
    gesellschaft_id: null,
    vertragsnummer: null,
    beginn: null,
    ablaufdatum: null,
    beitrag: null,
    zahlweise: null,
    kennzeichen: {
      unterscheidungszeichen: null,
      erkennungszeichen: null,
      erkennungsnummer: null,
      abschliessendeszeichen: null,
    },
    notiz: null,
  },
  async (data) => {
    await uploadDocumentsForContract(data.contract_id);
    trackEvent({ category: TrackEventCategory.CONTRACT, action: 'Erstellt', name: 'click' });
    clear();
    await fetchContractsAndDepots(true);
    finished.value = true;
  },
);

const {
  validFileTypes,
  validFiles,
  invalidFiles,
  fileNames,
  hasMultipleImageFiles,
  exceedsImageCombinationLimit,
  errorMessages,
  setFiles,
  setFileName,
  remove,
  reset: resetUpload,
  upload,
  loading: documentsLoading,
} = useDocumentUpload({ compressImages: true });
const { documentTypes, fetchDocumentTypes } = useDocumentTypes();

const { isMobile } = useDetectMobile();

const documentTypeOptions = toOptions(documentTypes, 'name', 'id');

watch(() => form.value.kennzeichen.unterscheidungszeichen, (value) => {
  form.value.kennzeichen.unterscheidungszeichen = toUpperCase(checkForLetters(value));
}, { flush: 'post' });

watch(() => form.value.kennzeichen.erkennungszeichen, (value) => {
  form.value.kennzeichen.erkennungszeichen = toUpperCase(checkForLetters(value));
}, { flush: 'post' });

watch(() => form.value.kennzeichen.erkennungsnummer, (value, oldValue) => {
  if (!value) return;
  const output = checkForDigits(value.toString());
  if (output !== null) {
    form.value.kennzeichen.erkennungsnummer = Number.parseInt(output, 10);
  } else if (oldValue !== null && Number.isInteger(oldValue)) {
    form.value.kennzeichen.erkennungsnummer = oldValue;
  } else {
    form.value.kennzeichen.erkennungsnummer = null;
  }
}, { flush: 'post' });

watch([hasMultipleImageFiles, exceedsImageCombinationLimit], () => {
  combine.value = hasMultipleImageFiles.value && !exceedsImageCombinationLimit.value;
});

watch(
  combine,
  () => validFiles.value.forEach((file, index) => {
    if (file.type.startsWith('image/')) {
      setFileName(file.name, index);
    }
  }),
);

const clear = () => {
  // contract related
  reset();
  // upload related
  documentType.value = null;
  addDocuments.value = false;
  resetUpload();
};

const uploadDocumentsForContract = async (contractId: number) => {
  hasUploadError.value = false;

  if (!(addDocuments.value && validFiles.value.length > 0)) return;

  try {
    await upload({
      contract_id: contractId,
      insurance_company_id: form.value.gesellschaft_id,
      product_combo_id: form.value.sparte_id,
      document_type_id: documentType.value,
      note: null,
      combine: combine.value,
    });
  } catch (error) {
    // We don't want an errorneous upload to fail the contract creation process
    // so we pass here. When an upload fails we render a message to the user
    hasUploadError.value = true;
    captureException(error);
  }
  trackEvent({ category: TrackEventCategory.DOCUMENT_UPLOAD, action: 'Hochladen', name: 'click' });
};

fetchDocumentTypes();
</script>
