<script setup lang="ts">
import { DsButton, DsDropdown, DsIcon } from '@demvsystems/design-components';
import { computed, ref } from 'vue';
import { Finanzmanager } from '@/application/router/types/types';
import { UnreadHintType } from '@/api/unreadHint/types';
import { storeToRefs } from 'pinia';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import MenuHint from '@/application/menu/components/MenuHint.vue';
import { useUserStore } from '@/user/store/userStore';

const { menuHints } = storeToRefs(useUnreadHintStore());
const { kundeVorname, kundeNachname, isCompany, kundeEmail } = storeToRefs(useBaseDataStore());

const accountLabel = kundeVorname?.value || kundeNachname?.value ? `${kundeVorname?.value} ${kundeNachname?.value}`
  : 'Meine Daten';
const unreadNewsHints = computed(() => menuHints.value[UnreadHintType.News]);

const shortAccountLabel = computed(() => kundeVorname?.value || kundeNachname?.value ?
  `${kundeVorname?.value?.charAt(0)}${kundeNachname?.value?.charAt(0)}`
  : kundeEmail.value?.charAt(0)
);

const userStore = useUserStore();

const accountItems = ref([
  { label: isCompany.value ? 'Unsere Daten' : 'Meine Daten', to: { name: Finanzmanager.DATEN }, icon: 'address-card' },
  { label: isCompany.value ? 'Unser Account' : 'Mein Account', to: { name: Finanzmanager.ACCOUNT }, icon: 'user' },
  // { label: 'Inbox', to: { name: Finanzmanager.HANDLUNGEN }, icon: 'list' },
  { label: 'Hinweise', to: { name: Finanzmanager.HINWEISE }, icon: 'circle-info', unreadHints: unreadNewsHints },
  { label: 'Abmelden', handler: userStore.logUserOutByUser, icon: 'right-from-bracket' },
]);
</script>

<template>
  <div
    v-if="userStore.isAuthenticated"
    class="hidden md:flex justify-end"
  >
    <DsDropdown
      :items="accountItems"
      :width="200"
      item-class="pt-0 pb-0"
    >
      <template #button="{ isOpen }">
        <div class="hidden lg:block relative">
          <DsButton
            variant="outline"
            :icon="isOpen ? 'chevron-up' : 'chevron-down'"
            icon-align="right"
          >
            {{ accountLabel }}
          </DsButton>
          <MenuHint
            :number="unreadNewsHints"
            style="transform: translateX(10px); top: -8px;"
          />
        </div>
        <div class="lg:hidden rounded-full bg-gray-300 flex flex-row items-center justify-center w-8 h-8">
          <span class="text-xs font-semibold text-white">
            {{ shortAccountLabel }}
          </span>
        </div>
      </template>
      <template #item="{ item }">
        <RouterLink
          v-if="item.to"
          :to="item.to"
          class="text-gray-900 hover:text-gray-900 hover:no-underline flex flex-row justify-start items-center
          gap-1 py-3 px-3"
        >
          <DsIcon
            :name="item.icon"
            variant="light"
            class="mr-1"
            fixed-width
          />
          <div class="relative">
            {{ item.label }}
            <MenuHint v-if="item.unreadHints != null" :number="item.unreadHints" />
          </div>
        </RouterLink>
        <div
          v-else
          class="text-gray-900 hover:text-gray-900 hover:no-underline flex flex-row justify-start items-center pointer
          gap-1 py-3 px-3"
          @click.prevent="item.handler"
        >
          <DsIcon
            :name="item.icon"
            variant="light"
            class="mr-1"
            fixed-width
          />
          <div class="relative">
            {{ item.label }}
            <MenuHint v-if="item.unreadHints != null" :number="item.unreadHints" />
          </div>
        </div>
      </template>
    </DsDropdown>
  </div>
</template>
