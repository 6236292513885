<template>
  <DsModal
    :show="show"
    hide-buttons
    @cancel="() => {
      $emit('close')
    }"
  >
    <ModalWrapper>
      <div class="flex">
        <DsButton
          class="ml-auto"
          variant="clear"
          icon="xmark-large"
          icon-variant="regular"
          @click="$emit('close')"
        />
      </div>

      <div class="flex flex-col justify-between -mb-2">
        <div
          v-if="isBroker"
          class="text-center mb-2.5 text-muted bg-light p-1.5 mt-4"
        >
          Sie beraten gerade {{ kundeVorname }} {{ kundeNachname }}
        </div>
        <div
          v-if="isAuthenticated"
          class="flex flex-col gap-5 py-4 px-2 border rounded-lg text-center"
          :class="[isBroker ? 'mb-4' : 'my-4']"
        >
          <div class="font-medium text-lg">
            Sie haben Fragen? Kontaktieren Sie {{ maklerIsAgency ? 'uns' : 'mich' }} gerne!
          </div>
          <div>
            <span v-if="firmenname">{{ firmenname }}</span>
            <span v-if="maklername && maklername !== firmenname"> - {{ maklername }}</span> <br>
            <div class="flex flex-col md:flex-row md:gap-5 items-center justify-center">
              <div
                v-if="maklertelefon"
                class="flex flex-row items-center space-x-2"
              >
                <DsIcon
                  name="phone"
                  variant="regular"
                  class="text-gray-500"
                />
                <span> {{ maklertelefon }}</span>
              </div>
              <div
                v-if="makleremail"
                class="flex flex-row items-center space-x-2"
              >
                <DsIcon
                  name="envelope"
                  variant="regular"
                  class="text-gray-500"
                />
                <a :href="`mailto:${makleremail}`">
                  {{ makleremail }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col divide-y divide-gray-100">
          <div
            v-for="item in menuItems"
            :key="item.label"
          >
            <RouterLink
              :to="item.to"
              class="flex flex-row items-center gap-3 hover:bg-gray-100 rounded px-2 py-2.5 hover:no-underline text-gray-900
              hover:text-gray-900 my-1 "
              @click.prevent="$emit('close')"
            >
              <DsIcon
                :name="item.icon"
                variant="light"
                size="lg"
                fixed-width
              />
              <div class="text-base relative">
                {{ item.label }}
                <MenuHint
                  v-if="item.unreadHints"
                  :number="item.unreadHints"
                />
              </div>
            </RouterLink>
          </div>

          <div>
            <div
              class="flex flex-row items-center gap-3 hover:bg-gray-100 rounded px-2 py-2.5 text-gray-900 mt-1 pointer"
              @click.prevent="userStore.logUserOutByUser"
            >
              <DsIcon
                name="right-from-bracket"
                variant="light"
                size="lg"
                fixed-width
              />
              <span class="text-base">
                Abmelden
              </span>
            </div>
          </div>
        </div>

        <div class="flex flex-row items-center justify-center gap-3 print:hidden mt-4 text-xs text-gray-500">
          Version: {{ versionString }} {{ liveUpdateChannel }}
        </div>

        <div class="flex flex-row items-center justify-center gap-5 print:hidden mt-5">
          <a
            v-if="isProductionEnv"
            href="#"
            onclick="CCM.openWidget();return false;"
            class="text-gray-500"
          >Cookies</a>
          <RouterLink
            :to="{ name: Finanzmanager.DATENSCHUTZ }"
            class="text-gray-500"
            @click.prevent="$emit('close')"
          >
            Datenschutz
          </RouterLink>
          <RouterLink
            :to="{ name: Finanzmanager.IMPRESSUM }"
            class="text-gray-500"
            @click.prevent="$emit('close')"
          >
            Impressum
          </RouterLink>
        </div>
      </div>
    </ModalWrapper>
  </DsModal>
</template>

<script setup lang="ts">
import { DsButton, DsIcon, DsModal } from '@demvsystems/design-components';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import { Finanzmanager } from '@/application/router/types/types';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import MenuHint from "@/application/menu/components/MenuHint.vue";
import { useUnreadHintStore } from "@/application/store/unreadHintStore";
import { UnreadHintType } from "@/api/unreadHint/types";
import { isProductionEnv } from "@/application/utils/envInfo";
import ModalWrapper from "@/application/components/ModalWrapper.vue";
import { useEnvInfo } from "@/application/composables/envInfo";
import { useUserStore } from "@/user/store/userStore";

interface Props {
  show: boolean;
}


defineOptions({ inheritAttrs: false });

defineProps<Props>();
defineEmits(['close']);

const { versionString, liveUpdateChannel } = useEnvInfo();

const userStore = useUserStore();
const {
  isAuthenticated,
  isCompany,
  isBroker,
  firmenname,
  makleremail,
  maklertelefon,
  maklername,
  maklerIsAgency,
  kundeVorname,
  kundeNachname,
} = storeToRefs(useBaseDataStore());
const unreadNews = useUnreadHintStore().menuHints[UnreadHintType.News];
const menuItems = ref([
  // { label: 'Inbox', to: { name: Finanzmanager.HANDLUNGEN }, icon: 'list', unreadHints: handlungCount },
  { label: 'Hinweise', to: { name: Finanzmanager.HINWEISE }, icon: 'circle-info', unreadHints: unreadNews },
  { label: isCompany.value ? 'Unsere Daten' : 'Meine Daten', to: { name: Finanzmanager.DATEN }, icon: 'address-card' },
  { label: isCompany.value ? 'Unser Account' : 'Mein Account', to: { name: Finanzmanager.ACCOUNT }, icon: 'user' },
]);
</script>
